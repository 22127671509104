<template>
  <div id="component" v-cus-loading="loading" class="container min-h-250">
    <div v-for="(itemPlan, index) in data" :key="index">
      <div
        :class="{ current: itemPlan.id === selected.id }"
        class="col col-select"
        @click="onClickPlan(itemPlan)"
      >
        <div class="card">
          <header class="card-header">
            <h3 class="title">{{ itemPlan.planName }} ({{ $tc('active') }})</h3>
            <span class="price">
              <!-- {{ itemPlan.planPriceYearly | formatToCurrencyWithIsoCode }} -->
            </span>
            <span class="description">{{ itemPlan.planDescription }}</span>
            <div class="line"></div>
          </header>
          <table class="features" width="100%">
            <tbody>
              <tr
                v-for="(itemFeature, indexFeature) in getFeatures(
                  itemPlan.features,
                )"
                :key="indexFeature"
              >
                <td align="center">
                  <span
                    v-if="
                      itemFeature.featureType === 'access' &&
                      itemFeature.featureValue === 'true'
                    "
                    class="tag success"
                  >
                    <i class="fad fa-check fa-lg"></i>
                  </span>
                  <span
                    v-if="
                      itemFeature.featureType === 'access' &&
                      itemFeature.featureValue === 'false'
                    "
                    class="tag danger"
                  >
                    <i class="fad fa-times fa-lg"></i>
                  </span>
                  <span
                    v-if="
                      itemFeature.featureType !== 'access' &&
                      itemFeature.featureValue > 0
                    "
                    class="tag success"
                  >
                    <small>{{ itemFeature.featureValue }}</small>
                  </span>
                  <span
                    v-if="
                      itemFeature.featureType !== 'access' &&
                      itemFeature.featureValue < 0
                    "
                    class="tag primary"
                  >
                    {{ $tc('unlimited') }}
                  </span>
                  <span
                    v-if="+itemFeature.featureValue === 0"
                    class="tag danger"
                  >
                    <i class="fad fa-times fa-lg"></i>
                  </span>
                </td>
                <td>
                  {{ itemFeature.featureName }}
                </td>
              </tr>
            </tbody>
          </table>
          <el-button
            size="mini"
            class="btn btn-select"
            @click="onClickPlan(itemPlan)"
          >
            <i class="fad fa-thumbs-up"></i>
            {{ `${$tc('select', 2)} ${$tc('plan', 2)}` }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { buildQueryString } from '@/helpers'
import { iframe } from '@/views/common'

export default {
  extends: {
    ...iframe({ frameName: 'plan:select-without-payment' }),
  },
  data() {
    return {
      data: [],
      selected: {},
      loading: false,
    }
  },
  computed: {
    planPrice: _this => _this.selected.planPriceYearly,
    planTaxBase: _this =>
      +_this.selected.planTaxPercentIncluded > 0
        ? +(
            _this.planPrice /
            (_this.selected.planTaxPercentIncluded / 100 + 1)
          ).toFixed(2)
        : _this.planPrice,
    planIvaBase: _this => _this.planTaxBase,
    planIvaPercent: _this =>
      +_this.selected.planTaxPercentIncluded ||
      +_this.selected.planTaxPercentIncrease,
    planIvaValue: _this =>
      +_this.selected.planTaxPercentIncluded > 0
        ? _this.planPrice - _this.planTaxBase
        : +(_this.planPrice * _this.selected.planTaxPercentIncrease).toFixed(2),
    planAmountTotal: _this =>
      +_this.selected.planTaxPercentIncrease > 0
        ? _this.planPrice + _this.planIvaValue
        : _this.planPrice,
  },
  mounted() {
    this.sendMessage({ type: 'loading:start', value: true })
    this.getData()
    this.listenFrameEvents()
  },
  methods: {
    onClickPlan(plan) {
      const _this = this
      this.selected = plan
      this.sendMessage({
        type: 'selected:plan',
        value: {
          ...plan,
          ...{
            planPrice: _this.planPrice,
            planTaxBase: _this.planTaxBase,
            planIvaBase: _this.planIvaBase,
            planIvaPercent: _this.planIvaPercent,
            planIvaValue: _this.planIvaValue,
            planAmountTotal: _this.planAmountTotal,
          },
        },
      })
    },
    getFeatures(features) {
      if (features && +features.length) {
        return features
          .filter(item => !(+item.featureValue === 0))
          .sort((a, b) => {
            const valueA =
              a.featureValue === 'true'
                ? 1
                : a.featureValue === 'false'
                ? -1
                : +a.featureValue < 0
                ? 1000000000
                : +a.featureValue
            const valueB =
              b.featureValue === 'false'
                ? -1
                : b.featureValue === 'true'
                ? 1
                : +b.featureValue < 0
                ? 1000000000
                : +b.featureValue

            return valueB - valueA
          })
      }

      return []
    },
    getData() {
      const _this = this

      const queryString = buildQueryString({
        serviceId: _this.$route.params.serviceId,
        planOnSite: true,
        planEnabled: true,
        limit: 100,
        planMode: _this.$route.params.mode,
      })
      _this.$set(_this, 'loading', true)
      _this.loading = true

      return _this.$http
        .get(`plans/search?${queryString}`)
        .then(response => {
          if (
            response.data &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            _this.data = response.data.data
          } else if (
            response.data &&
            response.data.data &&
            response.data.data.length < 1
          ) {
            _this.data = []
          } else {
            _this.data = response.data
          }
          this.$nextTick(() => {
            _this.sendMessageSizeFrame()
          })

          return _this.data
        })
        .finally(_ => (_this.loading = false))
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/transactions/select-plan.scss';
.col {
  width: auto;
  cursor: pointer;

  .features {
    margin: 2px auto;
  }
}
</style>
