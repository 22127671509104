var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"cus-loading",rawName:"v-cus-loading",value:(_vm.loading),expression:"loading"}],staticClass:"container min-h-250",attrs:{"id":"component"}},_vm._l((_vm.data),function(itemPlan,index){return _c('div',{key:index},[_c('div',{staticClass:"col col-select",class:{ current: itemPlan.id === _vm.selected.id },on:{"click":function($event){return _vm.onClickPlan(itemPlan)}}},[_c('div',{staticClass:"card"},[_c('header',{staticClass:"card-header"},[_c('h3',{staticClass:"title"},[_vm._v(_vm._s(itemPlan.planName)+" ("+_vm._s(_vm.$tc('active'))+")")]),_c('span',{staticClass:"price"}),_c('span',{staticClass:"description"},[_vm._v(_vm._s(itemPlan.planDescription))]),_c('div',{staticClass:"line"})]),_c('table',{staticClass:"features",attrs:{"width":"100%"}},[_c('tbody',_vm._l((_vm.getFeatures(
                itemPlan.features,
              )),function(itemFeature,indexFeature){return _c('tr',{key:indexFeature},[_c('td',{attrs:{"align":"center"}},[(
                    itemFeature.featureType === 'access' &&
                    itemFeature.featureValue === 'true'
                  )?_c('span',{staticClass:"tag success"},[_c('i',{staticClass:"fad fa-check fa-lg"})]):_vm._e(),(
                    itemFeature.featureType === 'access' &&
                    itemFeature.featureValue === 'false'
                  )?_c('span',{staticClass:"tag danger"},[_c('i',{staticClass:"fad fa-times fa-lg"})]):_vm._e(),(
                    itemFeature.featureType !== 'access' &&
                    itemFeature.featureValue > 0
                  )?_c('span',{staticClass:"tag success"},[_c('small',[_vm._v(_vm._s(itemFeature.featureValue))])]):_vm._e(),(
                    itemFeature.featureType !== 'access' &&
                    itemFeature.featureValue < 0
                  )?_c('span',{staticClass:"tag primary"},[_vm._v(" "+_vm._s(_vm.$tc('unlimited'))+" ")]):_vm._e(),(+itemFeature.featureValue === 0)?_c('span',{staticClass:"tag danger"},[_c('i',{staticClass:"fad fa-times fa-lg"})]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(itemFeature.featureName)+" ")])])}),0)]),_c('el-button',{staticClass:"btn btn-select",attrs:{"size":"mini"},on:{"click":function($event){return _vm.onClickPlan(itemPlan)}}},[_c('i',{staticClass:"fad fa-thumbs-up"}),_vm._v(" "+_vm._s(`${_vm.$tc('select', 2)} ${_vm.$tc('plan', 2)}`)+" ")])],1)])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }