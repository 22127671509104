import { sendMessage } from '@/helpers'

export default ({ frameName }) => ({
  methods: {
    sendMessageSizeFrame() {
      const height = document.getElementById('component').offsetHeight
      this.sendMessage({ type: 'frame:height', value: height })
    },
    listenFrameEvents() {
      window.onload = () => {
        this.sendMessageSizeFrame()
        this.sendMessage({ type: 'loading:end', value: false })
      }
      window.onresize = () => {
        this.sendMessageSizeFrame()
      }
    },
    sendMessage({ type, value }) {
      sendMessage({
        type,
        value,
        source: frameName,
      })
    },
  },
})
